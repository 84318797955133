<template>
  <div>
    <div
      v-if="loading"
      class="text-center d-flex justify-content-center align-items-center loader"
    >
      <b-spinner variant="primary" label="Text Centered"></b-spinner>
    </div>
    <b-card v-else>
      <b-form ref="form" class="p-1">
        <b-row>
          <b-col v-if="userRole == 'admin'" cols="6">
            <b-form-group
              id="input-group-2"
              label="Affiliated Company"
              label-for="Affiliated Company"
            >
              <b-form-input
                disabled
                v-model="affiliatedAdmin"
                required
                class="bg-light data-text"
              />
            </b-form-group>
          </b-col>
          <!-- <b-col v-else cols="6">
            <b-form-group
              invalid-feedback="Affiliated Company is required"
              :state="company"
            >
              <label for="">
                Affiliated Company
                <span class="text-danger">*</span></label
              >
              <v-select
                v-model="SelectedCompany"
                :state="company"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="companies"
                @input="getSisterConcernCompany"
                item-value="id"
                id="id"
                placeholder="Select Affiliated Company"
                class="bg-light data-text"
                :clearable="false"
              />
            </b-form-group>
          </b-col> -->
          <!-- <b-col cols="6">
            <b-form-group
              invalid-feedback="Affiliated Company is required"
              :state="Assignedcompany"
            >
              <label for="">
                Assigned Company
                <span class="text-danger">*</span></label
              >

              <v-select
                v-model="SelectedAssignedCompany"
                :state="Assignedcompany"
                @input="getAssignedCompany"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="assignedCompany"
                item-value="id"
                id="id"
                placeholder="Select Assigned Company"
                class="bg-light data-text"
              >
                <span slot="no-options" class="text-danger text-left">
                  please select affiliated company first</span
                >
              </v-select>
            </b-form-group>
          </b-col> -->
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              invalid-feedback="Category is required"
              :state="category"
            >
              <label for="">
                Category
                <span class="text-danger">*</span></label
              >
              <v-select
                v-model="SelectedCategory"
                :state="category"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="categories"
                item-value="id"
                id="id"
                placeholder="Select Category"
                @input="getJobTemplete"
                class="bg-light data-text"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              invalid-feedback="Job Title is required"
              :state="title"
            >
              <label for="">
                Job Title
                <span class="text-danger">*</span></label
              >
              <b-form-input
                id="input-2"
                v-model="form.title"
                :state="title"
                placeholder="Enter Job Title"
                required
                class="bg-light data-text"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="6">
            <b-form-group
              invalid-feedback="Working Hour is required"
              :state="workingHour"
            >
              <label for="">
                Working Hour/Day
                <span class="text-danger">*</span></label
              >
              <b-form-input
                id="hour"
                :state="workingHour"
                v-model="form.hours"
                placeholder="40hr/5d"
                required
                class="bg-light data-text"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group
              invalid-feedback="Last Date of Application is required"
              :state="lastDate"
            >
              <label for="">
                Last Date of Application
                <span class="text-danger">*</span></label
              >
              <b-form-datepicker
                id="example-datepicker"
                v-model="form.last_date"
                :state="lastDate"
                class="mb-2 bg-light"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              id="input-group-2"
              label="Vacancy"
              label-for="Vacancy"
            >
              <b-form-input
                id="input-2"
                v-model="form.vacancy"
                placeholder="Vacancy"
                required
                class="bg-light data-text"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <!-- <b-form-group
              id="input-group-2"
              label="Location"
              label-for="Location"
            > -->
            <b-form-group
              invalid-feedback="Location is required"
              :state="location"
            >
              <label for="">
                Location
                <span class="text-danger">*</span></label
              >
              <b-form-input
                id="input-2"
                v-model="form.location"
                :state="location"
                placeholder="Enter Your Location"
                required
                class="bg-light data-text"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              id="input-group-2"
              label="Description"
              label-for="Description"
            >
              <quill-editor
                v-model="form.description"
                class="bg-light data-text"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group
              id="input-group-2"
              label="Requirements"
              label-for="requirements"
            >
              <quill-editor
                v-model="form.requirements"
                class="bg-light data-text"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              id="input-group-2"
              label="Responsibilities"
              label-for="Responsibilities"
            >
              <quill-editor
                v-model="form.responsibility"
                class="bg-light data-text"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              id="input-group-2"
              label="Benefits"
              label-for="benefits"
            >
              <quill-editor
                v-model="form.benefits"
                class="bg-light data-text"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-4">
          <b-col class="d-flex flex-row justify-content-between">
            <b-row class="text-center">
              <b-col>
                <b-button
                  v-if="!$route.params.id"
                  @click.prevent="handleSubmit"
                  class="px-4 send-btn"
                >
                  Save
                </b-button>
                <b-button
                  v-if="$route.params.id"
                  @click.prevent="handleUpdate"
                  class="mx-1 px-4 send-btn"
                >
                  Update
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import { getLogedInUser } from "@/utils/auth";
// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";

import { toast } from "@/utils/toast";
import {
  BButton,
  BCard,
  BCol,
  BForm,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BFormTextarea,
  BFormValidFeedback,
  BRow,
  BSpinner,
} from "bootstrap-vue";
import { quillEditor } from "vue-quill-editor";
import vSelect from "vue-select";
export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckboxGroup,
    BFormDatepicker,
    BFormCheckbox,
    BButton,
    BCard,
    BFormTextarea,
    BFormInvalidFeedback,
    BFormValidFeedback,
    vSelect,
    quillEditor,
    BSpinner,
  },
  props: {
    form: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      companies: [],
      SelectedCompany: null,
      SelectedCategory: null,
      loading: false,
      SelectedAssignedCompany: null,
      assignedCompany: [],
      userRole: "",
      affiliatedAdmin: "",
      categories: [],
      fields: [],
      category_id: "",
      // validation variable section
      company: null,
      Assignedcompany: null,
      category: null,
      title: null,
      workingHour: null,
      lastDate: null,
      location: null,
      loggeInUser: JSON.parse(getLogedInUser()),
    };
  },
  created() {
    this.getCompanies();
    if (this.loggeInUser.roles[0].name === "admin") {
      this.getSisterConcernCompany();
    }
    if (this.loggeInUser.roles[0].name === "customer") {
      this.SelectedAssignedCompany = this.loggeInUser;
      if (this.loggeInUser.customer_to_company && this.loggeInUser.customer_to_company.length > 0){
        this.SelectedCompany = this.loggeInUser.customer_to_company[0];
      }
    }
    this.getFields();
    this.getCategories();
  },
  mounted() {
    setTimeout(() => {
      if (this.SelectedCategory == null) {
        this.SelectedCategory = this.form.category;
      }
      if (this.form.hours == undefined) {
        this.form.hours = this.form.hours;
      }

      if (this.form.description == undefined) {
        this.form.description = this.form.description;
      }

      if (this.form.skill == undefined) {
        this.form.skill = this.form.field;
      }
      // form.requirements
      if (this.form.requirements == undefined) {
        this.form.requirements = this.form.requirements;
      }
      if (this.form.responsibility == undefined) {
        this.form.responsibility = this.form.responsibility;
      }
      if (this.form.benefits == undefined) {
        this.form.benefits = this.form.benefits;
      }
    }, 2000);
  },
  methods: {
    // get all the companies
    async getCompanies() {
      this.userRole = JSON.parse(getLogedInUser()).roles[0].name;
      this.loading = true;
      let res = await this.$store.dispatch("company/getCompanies");
      this.companies = res.data;
      if (this.companies.length > 0) {
        if (this.$route.params.id) {
          this.loading = true;
          this.SelectedCompany = this.companies.find(
            (company) => company.id === this.form.user_id
          );
          if (this.SelectedCompany) {
            this.getSisterConcernCompany();
          }
          this.loading = false;
        }
      }
      this.loading = false;
    },
    getAssignedCompany() {
      if (this.SelectedAssignedCompany) {
        this.form.customer_id = this.SelectedAssignedCompany.id;
      }
    },
    async getSisterConcernCompany() {
      let companyID = null;
      if (JSON.parse(getLogedInUser()).roles[0].name === "admin") {
        companyID = JSON.parse(getLogedInUser()).id;
        this.affiliatedAdmin = JSON.parse(getLogedInUser()).name;
      } else {
        companyID = this.SelectedCompany?.id;
      }
      await this.$store
        .dispatch("company/getSisterConcernCompany", companyID)
        .then((response) => {
          this.assignedCompany = response.data;
          if (this.assignedCompany.length > 0) {
            this.SelectedAssignedCompany = this.assignedCompany.find(
              (company) => company.id == this.form.customer_id
            );
          }
        });
    },

    handleSubmit() {
      if (!this.checkFormValidity()) {
        return;
      }
      if (this.SelectedCompany) {
        this.form.company_id = this.SelectedCompany.id;
      } else {
        this.form.company_id = JSON.parse(getLogedInUser()).id;
      }
      if (this.SelectedCategory) {
        this.form.category = this.SelectedCategory.name;
      }
      if (this.SelectedAssignedCompany) {
        this.form.customer_id = this.SelectedAssignedCompany.id;
      }
      this.form.short_description = "not requred so romoved ";
      this.form.responsibilities = this.form.responsibility;
      this.loading = true;
      this.$store
        .dispatch("job/storeJob", this.form)
        .then((response) => {
          if (response.status_code === 200) {
            toast(
              "Success",
              "CheckIcon",
              "success",
              "Job Created Successfully & Saved as Draft"
            );
            this.$router.push({ name: "job-post-draft" });
            this.loading = false;
          }
        })
        .catch((error) => {
          toast("Error", "AlertTriangleIcon", "danger", "Error Creating Job");
          this.loading = false;
        });
    },
    handleUpdate() {
      if (this.SelectedCompany) {
        this.form.company_id = this.SelectedCompany.id;
      } else {
        this.form.company_id = JSON.parse(getLogedInUser()).id;
      }
      if (this.SelectedCategory) {
        // form.category
        this.form.category = this.SelectedCategory.name;
      }
      if (this.SelectedAssignedCompany) {
        this.form.customer_id = this.SelectedAssignedCompany.id;
      }
      this.form.short_description = "not requred so romoved ";
      // this.form.responsibility = "empty";
      this.form.file = "empty";
      this.form.user_id = this.form.company_id;
      this.form.hours = this.form.hours;
      this.form.description = this.form.description;
      this.form.field = this.form.skill;
      this.form.requirements = this.form.requirements;
      this.form.responsibility = this.form.responsibility;
      this.form.benefits = this.form.benefits;
      this.loading = true;
      this.$store
        .dispatch("job/updateJob", this.form)
        .then((response) => {
          if (response.status_code === 200) {
            toast(
              "Success",
              "CheckIcon",
              "success",
              "Job Updated Successfully"
            );
            this.loading = false;
            this.$router.push({ name: "job-post-draft" });
          }
        })
        .catch((error) => {
          this.loading = false;
          toast("Error", "AlertTriangleIcon", "danger", "Error Updating Job");
        });
    },
    getFields() {
      this.$store.dispatch("field/getFields", this.query).then((response) => {
        this.fields = response.data.data;
      });
    },
    getCategories() {
      this.$store
        .dispatch("category/getCategories", this.query)
        .then((response) => {
          this.categories = response.data.data;
        });
    },
    // this function is for get all job templete
    getJobTemplete() {
      if (this.SelectedCategory) {
        // form.category
        this.category_id = this.SelectedCategory.id;
      }
      this.$store
        .dispatch("job/getJobTemplete", this.category_id)
        .then((response) => {
          if (response.data[0] !== undefined) {
            this.form = response.data[0];
          }
        })
        .catch((error) => {
          toast("Error", "AlertTriangleIcon", "danger", "Error Creating Job");
          this.loading = false;
        });
    },
    // job create form validation method
    checkFormValidity() {
      let valid = this.$refs.form.checkValidity();

      if (this.SelectedCompany !== null) {
        if (this.SelectedCompany?.id) {
          valid = true;
          this.company = valid;
        }
      } else {
        valid = false;
        this.company = valid;
      }
      // Assignedcompany
      if (this.SelectedAssignedCompany !== null) {
        if (this.SelectedAssignedCompany?.id) {
          valid = true;
          this.Assignedcompany = valid;
        }
      } else {
        valid = false;
        this.Assignedcompany = valid;
      }
      // category no giving feedback
      if (this.SelectedCategory !== null) {
        if (this.SelectedCategory?.id) {
          valid = true;
          this.category = valid;
        }
      } else {
        valid = false;
        this.category = valid;
      }

      if (this.form.title) {
        valid = true;
        this.title = valid;
      } else {
        valid = false;
        this.title = valid;
      }
      // :state="workingHour"
      if (this.form.hours) {
        valid = true;
        this.workingHour = valid;
      } else {
        valid = false;
        this.workingHour = valid;
      }

      if (this.form.last_date) {
        valid = true;
        this.lastDate = valid;
      } else {
        valid = false;
        this.lastDate = valid;
      }

      if (this.form.location !== null || this.form.location !== '') {
        valid = true;
        this.location = valid;
      } else {
        valid = false;
        this.location = valid;
      }
      return valid;
    },
  },
};
</script>

<style scoped>
.btn-space {
  margin: 2px;
}

.data-text {
  /* 18 bold */
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height */
  /* blue main */
  color: #01185e;
}

.decline {
  background-color: #ff4f5d1a !important;
  color: #ff4f5d !important;
  border-color: transparent !important;
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 21px;
}

.send-btn {
  background: linear-gradient(315deg, #01185e 0%, #264296 44.92%, #7190ef 100%);

  border-color: transparent !important;
  background: linear-gradient(315deg, #01185e 0%, #264296 44.92%, #7190ef 100%);
}

.preview {
  border-color: transparent !important;
  background: linear-gradient(315deg, white 100%, white 100%, white 100%);
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
